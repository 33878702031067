table {
    border: 1px solid #ddd;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  
  td {
    font-size: 16px;
    color: #555;
  }
  